const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

const formatDate = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-") +
    " " +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(":")
  );
};

const extractDate = (date) => {
  return date.split("T")[0];
};

export const converteReservationsToEvents = (
  name,
  reservations,
  { reservationsStart, reservationsEnd }
) => {
  const start = extractDate(reservationsStart);
  const end = extractDate(reservationsEnd);
  const result = reservations
    .filter((reservation) => {
      if (!name || name === "") return true;
      else return reservation.resourceName.toLocaleLowerCase() === name;
    })
    .map((reservation) => {
      const { resourceName, startDate, endDate, color } = reservation;
      return {
        name: resourceName,
        start: formatDate(new Date(startDate)),
        end: formatDate(new Date(endDate)),
        color,
      };
    });
  return { result, start, end };
};
