const BASE_URL = "https://kimai.jugurtha.me/Web/Services";
const authenticate = async () => {
  const username = "admin";
  const password = "Jugurtha95260*";

  try {
    const { sessionToken, userId } = await fetch(
      `${BASE_URL}/Authentication/Authenticate`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      }
    ).then((res) => res.json());

    return { sessionToken, userId };
  } catch (error) {
    console.error("error authentication");
    throw error;
  }
};

export const getReservations = async () => {
  const { sessionToken, userId } = await authenticate();
  const { reservations, startDateTime, endDateTime } = await fetch(
    `${BASE_URL}/Reservations`,
    {
      method: "GET",
      headers: {
        "x-booked-sessiontoken": sessionToken,
        "x-booked-userid": userId,
      },
    }
  ).then((res) => res.json());
  return { reservations, startDateTime, endDateTime };
};
