<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert
          class="mt-2"
          color="primary"
          border="left"
          elevation="2"
          colored-border
          icon="mdi-information"
        >
          Les calendriers sont affichés sur les <strong>7</strong> derniers
          jours et jusqu'au prochain mois.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-sheet tile height="54" class="d-flex">
        <v-toolbar flat>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="$refs.calendar.prev()"
          >
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="$refs.calendar.next()"
          >
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-select
            v-model="type"
            :items="items"
            label="Affichage"
            class="mt-4"
          >
          </v-select>
        </v-toolbar>
      </v-sheet>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :type="type"
            :events="events"
            locale="fr-FR"
            :end="dates.end"
            interval-height="18"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getReservations } from "@/services/api";
import { converteReservationsToEvents } from "@/services/calendar";
export default {
  name: "HelloWorld",
  props: {
    name: { type: String, required: true },
  },
  data: () => ({
    reservations: [],
    dates: {},
    events: [],
    items: [
      { value: "month", text: "mois" },
      { value: "4day", text: "semaine" },
      { value: "day", text: "jour" },
    ],
    type: "4day",
    /* Calendar */
    focus: "",
  }),
  async created() {
    const { reservations, endDateTime, startDateTime } =
      await getReservations();
    this.reservations = reservations;
    const { result, start, end } = converteReservationsToEvents(
      this.name,
      this.reservations,
      {
        reservationsStart: startDateTime,
        reservationsEnd: endDateTime,
      }
    );
    this.events = result;
    this.dates = { start, end };
  },
};
</script>
